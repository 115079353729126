import React from "react";
import { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import axios from "axios";
import {
  Animator,
  ScrollContainer,
  ScrollPage,
  page,
  batch,
  Fade,
  FadeIn,
  FadeOut,
  Move,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  StickyOut,
  Zoom,
  ZoomIn,
  ZoomOut,
} from "react-scroll-motion";
import Footer from "./../common/Footer";
import "./../pages/company/company.css";
import Menu from "./../common/Menu";
import Location from "./../common/contact/Location";
import { Helmet } from "react-helmet";
import SolutionLeads from "../common/solutionleads";
//import { useGSAP } from 'gsap';
function Contact() {
  const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn(50));
  const FadeUp = batch(Fade(), Move(), Sticky());

  gsap.registerPlugin(ScrollTrigger);
  const main = useRef();
  
  const boxes = useRef([]);
  const [mailId, setMailId] = useState('');
  const [msg, setMsg] = useState('');

  const handleSubscription = async () => {
    const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/addsubscription`, {emailId:mailId});
    if (res?.status === 200) {
      setMsg('Thanks for Subscription.')
    }
  }

  useEffect(() => {
    const boxes = gsap.utils.toArray(".box");
    boxes.forEach((box) => {
      gsap.to(box, {
        x: 0,
        scrollTrigger: {
          trigger: box,
          start: "bottom bottom",
          stagger: 2,
          end: "top 50%",
          scrub: true,
          // markers: true,
        },
      });
    });
  }, []);

  useEffect(() => {
    // Define animation to animate each letter of the text
    const animateText = (element) => {
      const text = element.textContent.trim();
      element.textContent = ""; // Clear the text content

      text.split("").forEach((char, index) => {
        const letter = document.createElement("span");
        letter.textContent = char === " " ? "\u00A0" : char; // Add non-breaking space for regular space
        letter.style.opacity = 0;
        letter.style.display = "inline-block";
        gsap.to(letter, {
          opacity: 1,
          duration: 0.5,
          ease: "power2.out",
          delay: index * 0.1, // Add delay for each letter
        });
        element.appendChild(letter);
      });
    };

    // Set up ScrollTriggers for text animations
    const animateElements = document.querySelectorAll(".animate-text-2");
    animateElements.forEach((element) => {
      ScrollTrigger.create({
        trigger: element,
        start: "top bottom-=100",
        end: "top center",
        onEnter: () => animateText(element),
        once: false, // Animation will happen only once
      });
    });

    // Define animation to move element from left to right
    const moveLeftToRight = (element) => {
      gsap.to(element, {
        x: "-15%",
        opacity: 1,
        duration: 1,
        ease: "power2.inOut",
      });
    };

    // Define animation to move element from right to left
    const moveRightToLeft = (element) => {
      gsap.to(element, {
        x: "15%",
        opacity: 1,
        duration: 1,
        ease: "power2.inOut",
      });
    };

    const animat = document.querySelectorAll(".animate-element-5");
    animat.forEach((element) => {
      ScrollTrigger.create({
        trigger: element,
        start: "top bottom+=50",
        end: "top center",
        onEnter: () => moveLeftToRight(element),
        onLeaveBack: () => moveRightToLeft(element),
        onEnterBack: () => moveRightToLeft(element),
        onLeave: () => moveLeftToRight(element),
      });
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Contact Us | Procal</title>
        <meta
          name="description"
          content="Our diverse team brings a world of unique perspectives and niche capabilities to every project we undertake, serving clients worldwide with unparalleled dedication and excellence. Discover how our global presence enables us to deliver tailor-made solutions that transcend borders and exceed expectations."
        />
      </Helmet>
      <Menu />

      <div className="exact"></div>

     <Location /> 

      <section className="px-4 py-5 p-5 pb-5 sec13">
          <div className="container-fluid">
            <div className="row py-lg-5w">
              <div className="col-lg-8 offset-lg-1 col-md-8 mx-auto text-white botm-20">
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center">Experience the Procal Difference</h2>
                <h3 className="text-center mt-5 white-color">We want to hear from you!</h3>
                {msg !== '' && (<p className="info">{msg}</p>)}
                <div className="input-container contactinput">
                  <input type="text" onChange={(e) => setMailId(e?.target?.value)} value={mailId} className="input-field mt-5" placeholder="Enter your E-mail address.." />
                  <button className="send-button  mt-5" onClick={handleSubscription}>&#10148;</button>
                </div>
              </div>

            </div>
          </div>

        </section>
        <SolutionLeads/>
      <Footer />
    </>
  );
}

export default Contact;
